<template>
  <div
    class="flex flex-col min-h-screen justify-center items-center space-y-3 p-4 font-heading"
    v-if="!!discount"
  >
    <img :src="discount.vendor.logo.url" alt="" />
    <h2 class="text-2xl font-bold">{{ discount.vendor.name }}</h2>
    <div
      class="w-full p-6 rounded-3xl text-white flex flex-col space-y-2 items-center"
      :class="`${!used ? 'bg-orange' : 'bg-orange-tint'}`"
    >
      <img
        :src="user.img_url || this.$store.state.filler_img"
        alt=""
        class="w-24 h-24 object-cover object-center rounded-full bg-white"
        v-if="user"
      />
      <h2 class="text-xl font-bold capitalize" v-if="user">{{ user.name }}</h2>
      <p class="text-lg font-bold capitalize" v-if="user">
        {{ user.university.name }}
      </p>
      <h2 class="text-3xl font-bold uppercase">
        {{ getDiscountText(discount) }}
      </h2>
      <Button shape="full" color="secondary" @click="use" v-if="!used"
        >Use</Button
      >
      <Button shape="ghost" color="secondary" v-else>Used!</Button>
    </div>
  </div>
</template>

<script>
import { Query } from 'tutoruu-bridge';
import { getDiscount, createDiscountUse } from '../../helpers/cms';
import Button from '../../components/Feed/Button.vue';
import mixpanel from 'mixpanel-browser';

export default {
  components: { Button },
  data() {
    return {
      discount_id: this.$route.params.id,
      user_id: this.$route.params.user_id,
      discount: null,
      user: null,
      used: false,
    };
  },
  created() {
    Query(
      'user',
      /* GraphQL */ `
                user(_id: "${this.user_id}") {
                    _id
                    name
                    email
                    phone
                    university {
                      name
                    }
                    img_url
                }
        `
    ).then((data) => {
      this.user = data.user;

      getDiscount(this.discount_id).then((discount) => {
        this.discount = discount;

        mixpanel.track('Discount QR Code Scanned', {
          user: this.user,
          discount,
        });
      });
    });
  },
  methods: {
    getDiscountText(discount) {
      return `${discount.discountValue} ${
        discount.discountType === 'Percentage' ? '%' : 'EGP'
      } Off`;
    },
    use() {
      createDiscountUse(this.discount_id, this.user.email).then(() => {
        this.used = true;

        mixpanel.track('Discount Used', {
          user: this.user,
          discount: this.discount,
        });
      });
    },
  },
};
</script>
